<template>
  <div class="container">
    <!-- Campo de búsqueda -->
    <div class="search-bar mb-4">
      <input 
        type="text" 
        v-model="searchQuery" 
        placeholder="Buscar juegos..." 
        class="form-control"
      />
    </div>

    <!-- Lista de juegos -->
    <div class="row">
      <router-link
        v-for="game in games"
        :key="game.id"
        :to="'/game/' + game.id"
        class="col-md-3 col-sm-6 mb-4"
      >
        <GameCard
          :game="game"
          :coverUrl="game.coverUrl"
          :genres="game.genres"
        />
      </router-link>
    </div>

    <!-- Paginación -->
    <PaginationList
      :currentPage="currentPage"
      :totalPages="totalPages"
      @page-change="changePage"
      class="mt-4"
    />
  </div>
</template>

<script>
import GameCard from './GameCard.vue';
import PaginationList from './PaginationList.vue';

import { debounce } from 'lodash';
import { generateImageUrl } from '../utils/imageUtils';
import axiosInstance from '../utils/axiosInstance';

export default {
  props: {
    minRating: {
      type: Number,
      default: 50,
    },
  },
  components: {
    GameCard,
    PaginationList,
  },
  data() {
    return {
      games: [],
      currentPage: 1,
      itemsPerPage: 12,
      totalGames: 0,
      searchQuery: '',
    };
  },
  computed: {
    totalPages() {
      return Math.max(Math.ceil(this.totalGames / this.itemsPerPage), 1);
    },
  },
  methods: {
    
    // MÉTODO PARA OBTENER LOS JUEGOS
    async fetchGames() {
      const offset = (this.currentPage - 1) * this.itemsPerPage;
      const searchClause = this.searchQuery ? `search "${this.searchQuery}";` : '';
      const sortClause = this.searchQuery ? '' : 'sort total_rating desc;';

      const requestBody = `
        ${searchClause}
        fields name,cover.url,total_rating,total_rating_count,genres.name;
        where total_rating >= ${this.minRating} & total_rating_count > 20;
        ${sortClause}
        limit ${this.itemsPerPage};
        offset ${offset};
      `;

      try {
        const response = await axiosInstance.post('http://217.160.4.144:8080/twitch/games', requestBody, {
          headers: { 'Content-Type': 'text/plain' },
        });

        this.games = response.data
          .filter(game => game.cover && game.cover.url)
          .map(game => ({
            ...game,
            coverUrl: generateImageUrl(`https:${game.cover.url}`, 'screenshot_med'),
            genres: game.genres.map(genre => genre.name), // Extraer solo los nombres
          }));

        if (response.headers['x-total-count']) {
          this.totalGames = parseInt(response.headers['x-total-count'], 10);
        } else {
          this.totalGames = 100; // Fallback
        }
      } catch (error) {
        console.error('Error fetching games:', error);
      }
    },

    // MÉTODO PARA CAMBIAR DE PÁGINA
    changePage(newPage) {
      this.currentPage = newPage;
      this.fetchGames();
    },
  },
  watch: {
    searchQuery: debounce(function () {
      this.fetchGames();
    }, 500),
    minRating() {
      this.fetchGames();
    },
  },
  created() {
    this.fetchGames();
  },
};
</script>


<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
button {
  padding: 10px 20px;
}
.search-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

</style>
