<template>
  <header class="page-header">
    <button @click="goBack" class="back-button" style="display: block; margin:  auto;">
      &#8592; Volver
    </button>
  </header>

  <div v-if="gameDetails" class="game-detail-card">

    <!-- Portada del juego -->
    <div class="game-detail-header">
      <img 
      :src="gameDetails.coverUrl"
      alt="Portada de {{ gameDetails.name }}"
      class="game-cover"
      />

    </div>

    <div class="game-detail-content">
      <!-- Título del juego -->
      <h1 class="game-title">{{ gameDetails.name }}</h1>

      <!-- Resumen del juego -->
      <p><strong>Resumen:</strong> {{ gameDetails.summary }}</p>
      
      <!-- Fecha de lanzamiento -->
      <p><strong>Fecha de lanzamiento:</strong> {{ new Date(gameDetails.first_release_date * 1000).toLocaleDateString() }}</p>

      <!-- Calificación -->
      <p><strong>Calificación:</strong> {{ Math.floor(gameDetails.rating) }} / 100</p>

      <!-- Géneros -->
      <p><strong>Géneros:</strong> {{ gameDetails.genres.join(', ') }}</p>
      

      <!-- Calificar el juego -->
    <div class="rating-section">
      <h2>Califica este juego</h2>
      <input 
        type="number" 
        v-model.number="userRating" 
        min="0" 
        max="99" 
        placeholder="Ingresa una calificación (0-99)" 
        class="rating-input"
        @focus="checkAuth"
      />
    </div>

    <!-- Calificar el juego -->
    <div class="review-section">
      <h2>Escribe tu reseña</h2>
      <textarea 
        v-model="userReview" 
        placeholder="Escribe aquí tu reseña..." 
        class="review-textarea"
        @focus="checkAuth"
      ></textarea>

      <!-- Botón para enviar la reseña o modificarla -->
      <button 
      v-if="isReviewExisting" 
      @click="modifyReview" 
      class="submit-review-btn modify-review-btn">
      Modificar reseña
      </button>
      <button 
      v-if="!isReviewExisting"
      @click="submitReview"
      @focus="checkAuth" 
      class="submit-review-btn">
      Enviar reseña
      </button>


      <!-- Mensaje de confirmación -->
      <p v-if="reviewSubmitted" class="review-success">¡Gracias por tu reseña!</p>
    </div>
    </div>
    
    

  </div>

  <div v-else>
    <p v-if="error">{{ error }}</p>
    <p v-else>Cargando detalles del juego...</p>
  </div>


  <!-- Modal de autenticación -->
  <div v-if="showAuthModal" class="auth-modal">
    <div class="auth-modal-content">
      <p>Debes iniciar sesión para calificar o escribir una reseña.</p>
      <button @click="redirectToLogin" class="modal-btn">Iniciar sesión</button>
      <button @click="closeAuthModal" class="modal-btn">Cerrar</button>
    </div>
  </div>

  <!-- Sección de todas las reseñas de los usuarios -->
  <!-- <div class="reviews-section"> -->
  <div class="game-detail-card">
    <div class="game-detail-content">
      <h2>Reseñas de otros usuarios</h2>
      <div v-if="reviews.length > 0" class="reviews-list">
        <div v-for="review in reviews" :key="review.id" class="review-card">
          <p><strong>Usuario:</strong> {{ review.username }}</p>
          <p><strong>Calificación:</strong> {{ review.rating }} / 100</p>
          <p><strong>Comentario:</strong> {{ review.reviewText }}</p>
          <p class="review-date">{{ new Date(review.createdAt).toLocaleDateString() }}</p>
        </div>
      </div>
      <p v-else>Sin reseñas...</p>
    </div>

    
  </div>
  
  <footer class="page-footer">
    <button @click="goBack" class="back-button">
      &#8592; Volver
    </button>
  </footer>

  
</template>
  
  
<script>
 import axiosInstance from '@/utils/axiosInstance';
  import { generateImageUrl } from '../utils/imageUtils';

  
  export default {
    
    // DATOS DEL COMPONENTE
    data() {
      return {
        gameDetails: null,
        error: null,
        showAuthModal: false,               // Indica si se muestra el modal de autenticación
        
        // Calificación y reseñas
        userRating: null, 
        userReview: '',
        reviewSubmitted: false,             // Indica si la reseña fue enviada con éxito
        isReviewExisting: false,            // Indica si ya existe una reseña
        reviews: [],                        // Lista de reseñas de otros usuarios
      };
    },


    // INICIALIZACIÓN AL CARGAR LA PÁGINA
    created() {
      const gameId = this.$route.params.id;
      const token = localStorage.getItem('authToken');

      this.fetchGameDetails(gameId).then(() => {
        this.fetchReviews(gameId);
        if (token) {
          this.checkUserReview(gameId);
        }
      });
    },

    methods: {

      // MÉTODO PARA VOLVER A LA PÁGINA DE JUEGOS
      goBack() {
        this.$router.push({ name: 'Games' });
      },

      // MÉTODO PARA OBTENER LOS DETALLES DEL JUEGO
      async fetchGameDetails(gameId) {
        try {
          const response = await axiosInstance.get(`http://217.160.4.144:8080/twitch/games/${gameId}`);
          const game = response.data[0];  // Los datos vienen en un array, tomamos el primer objeto
  
          // Verifica que la portada existe y asigna la URL correctamente
          if (game.cover && game.cover.url) {
            game.coverUrl = generateImageUrl(`https:${game.cover.url}`, '1080p');
          }
  
          if (game.genres) {
            game.genres = game.genres.map(genre => genre.name);
          }
          // Asigna los datos al objeto gameDetails
          this.gameDetails = game;

        } catch (error) {
          this.error = 'No se pudo obtener los detalles del juego';
          console.error(error);
        }
      },

      // MÉTODO PARA VERIFICAR SI EL USUARIO YA CALIFICÓ ESTE JUEGO
      async checkUserReview(gameId) {
        try {
            // Verificar autenticación
            const token = localStorage.getItem('authToken');

            const response = await axiosInstance.get(`http://217.160.4.144:8080/api/reviews/game/${gameId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
            },
            });

            if (response.status === 200) {
              const review = response.data;
              this.userRating = review.rating;
              this.userReview = review.reviewText;
              this.isReviewExisting = true;               // Marca que ya existe una reseña
              console.log('Reseña encontrada:', review);  // Depuración
              
            } else if (response.status === 204) {
              console.log('No hay reseña existente para este juego.');
              this.isReviewExisting = false;
            }

        } catch (error) {
          console.error('No se pudo cargar la reseña del usuario:', error);
          this.isReviewExisting = false;
        }
      },

      // MÉTODO PARA ENVIAR LA RESEÑA
      async submitReview() {

        // Verificar autenticación
        const token = localStorage.getItem('authToken');
        if (!token) {
          alert('Debes iniciar sesión antes de enviar una reseña.');
          this.$router.push('/login'); // Redirige al usuario a la página de login
          return;
        }

        // Verificar que la calificación sea válida
        if (this.userRating === null || this.userRating < 0 || this.userRating > 99) {
          alert("Por favor, introduce una calificación válida entre 0 y 99.");
          return;
        }

        // Construir el objeto con los datos de la reseña
        const reviewData = {
          twitchGameId: this.gameDetails.id,
          rating: this.userRating,
          reviewText: this.userReview
        };

        try {

          await axiosInstance.post('http://217.160.4.144:8080/api/reviews/rate', reviewData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          });
          
          this.reviewSubmitted = true;

        } catch (error) {
          console.error('Error al enviar la reseña:', error);
          if (error.response) {
            console.error('Status:', error.response.status);
            console.error('Data:', error.response.data);
            console.error('Headers:', error.response.headers);
          }
          alert('Hubo un problema al enviar la reseña. Inténtalo de nuevo más tarde.');
        }
      }, 

      // MÉTODO PARA MODIFICAR LA RESEÑA
      async modifyReview() {
        if (this.userRating === null || this.userRating < 0 || this.userRating > 99) {
          alert("Por favor, introduce una calificación válida entre 0 y 99.");
          return;
        }

        const reviewData = {
          twitchGameId: this.gameDetails.id,
          rating: this.userRating,
          reviewText: this.userReview,
        };

        try {
          const token = localStorage.getItem('authToken');
          await axiosInstance.put(`http://217.160.4.144:8080/api/reviews/rate`, reviewData, {
            headers: { Authorization: `Bearer ${token}` },
          });
          this.reviewSubmitted = true;
        } catch (error) {
          console.error('Error al modificar la reseña:', error);
        }
      }, 

      // MÉTODO PARA OBTENER LAS RESEÑAS DE OTROS USUARIOS
      async fetchReviews(gameId) {
          try {
            const response = await axiosInstance.get(`http://217.160.4.144:8080/api/reviews/game/${gameId}/all`);
            this.reviews = response.data;
          } catch (error) {
            console.error('Error al cargar las reseñas:', error);
          }
        },

      // MÉTODO PARA VERIFICAR LA AUTENTICACIÓN DEL USUARIO
      checkAuth() {
          const token = localStorage.getItem('authToken');
          if (!token) {
            this.openAuthModal();
          }
        },
      

      // MÉTODO PARA ABRIR EL MODAL DE AUTENTICACIÓN
      openAuthModal() {
        this.showAuthModal = true;
      },

      // MÉTODO PARA CERRAR EL MODAL DE AUTENTICACIÓN
      closeAuthModal() {
        this.showAuthModal = false;
      },

      // MÉTODO PARA REDIRIGIR AL USUARIO A LA PÁGINA DE LOGIN
      redirectToLogin() {
        this.$router.push('/login');
      },
    },
  }
  </script>
  
