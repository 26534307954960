<template>
  <div id="app">
    <NavBar />
    <router-view /> <!-- Lugar donde se cargará Home.vue u otras vistas según la ruta -->
    <footer>
      <br>
      <p style="text-align: center;">&copy; 2024 ReviewPlay - Reservados todos los derechos</p>
    </footer>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';

export default {
  components: {
    NavBar,
  },
};
</script>
