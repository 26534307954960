<template>
    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 1">Anterior</button>
      <span>Página {{ currentPage }} de {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">Siguiente</button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      currentPage: {
        type: Number,
        required: true
      },
      totalPages: {
        type: Number,
        required: true
      }
    },
    methods: {
      prevPage() {
        this.$emit("page-change", this.currentPage - 1);
      },
      nextPage() {
        this.$emit("page-change", this.currentPage + 1);
      }
    }
  };
  </script>
  
  <style scoped>
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  button {
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    background-color: #333;
    color: white;
    border: none;
  }
  </style>
  