<template>
  <div class="container login-container mt-5">
    <div class="card shadow p-4 bg-dark text-light">
      <h2 class="text-center mb-4">Iniciar Sesión</h2>
      <form @submit.prevent="handleLogin">
        <div class="mb-3">
          <label for="username" class="form-label">Usuario:</label>
          <input
            type="text"
            v-model="username"
            id="username"
            class="form-control gamer-input"
            required
          />
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Contraseña:</label>
          <input
            type="password"
            v-model="password"
            id="password"
            class="form-control gamer-input"
            required
          />
        </div>
        <button type="submit" class="btn btn-gamer w-100">Entrar</button>
      </form>
      <p v-if="errorMessage" class="error mt-3 text-center">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance';

export default {
  data() {
    return {
      username: '',
      password: '',
      errorMessage: null,
    };
  },
  methods: {
    async handleLogin() {
      try {
        const response = await axiosInstance.post('http://217.160.4.144:8080/api/users/login', {
          username: this.username,
          password: this.password,
        });

        localStorage.setItem('authToken', response.data.token);
        localStorage.setItem('username', this.username);  
        this.$router.push('/');
      } catch (error) {
        this.errorMessage = 'Error al iniciar sesión. Por favor, revisa tus credenciales.';
      }
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.login-container {
  max-width: 400px;
  margin: 0 auto;
}

.card {
  border-radius: 10px;
}

h2 {
  font-family: 'Press Start 2P', cursive;
  font-size: 1.5rem;
  color: #f5c518;
}

.form-label {
  font-family: 'Press Start 2P', cursive;
  color: #f5c518;
  font-size: 0.8rem;
}

.gamer-input {
  font-family: 'Press Start 2P', cursive;
  background-color: #333;
  color: #f5c518;
  border: 1px solid #555;
}

.btn-gamer {
  font-family: 'Press Start 2P', cursive;
  background-color: #4CAF50;
  color: #ffffff;
  border: none;
  font-size: 0.9rem;
}

.error {
  color: #ff6347;
  font-family: 'Press Start 2P', cursive;
}
</style>
