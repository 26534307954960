import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import GamesPage from '../views/GamesPage.vue';
import LoginPage from '../views/LoginPage.vue';
import RegisterPage from '../views/RegisterPage.vue';
import AboutPage from '../views/AboutPage.vue'; 
import GameDetailPage from '../views/GameDetailPage.vue'; 

const routes = [
  { path: '/', name: 'Home', component: HomePage },
  { path: '/home', name: 'Home', component: HomePage },
  { path: '/games', name: 'Games', component: GamesPage },
  { path: '/login', name: 'Login', component: LoginPage },
  { path: '/register', name: 'Register', component: RegisterPage },
  { path: '/about', name: 'About', component: AboutPage },
  { path: '/game/:id', name: 'GameDetail', component: GameDetailPage }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
