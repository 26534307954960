<!-- src/components/RatingFilter.vue -->
<template>
    <div class="filter-container">
      <label for="rating-filter">Filtrar por valoración mínima:</label>
      <select id="rating-filter" v-model="selectedRating" @change="applyFilter">
        <option v-for="rating in ratings" :key="rating" :value="rating">{{ rating }}+</option>
      </select>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        ratings: [50, 60, 70, 80, 90], // Valores de calificación
        selectedRating: 50, // Valor inicial
      };
    },
    methods: {
      applyFilter() {
        this.$emit("filter-rating", this.selectedRating);
      },
    },
  };
  </script>
  
  <style scoped>
  .filter-container {
    margin-bottom: 20px;
  }
  </style>
  