import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: '/api', // Esto usará el proxy configurado en `vue.config.js`
  headers: {
    'Content-Type': 'application/json',
  },
});

export default {
  install: (app) => {
    app.config.globalProperties.$axios = axiosInstance; // Para Vue 3
  },
};
