import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Asegúrate de tener solo una importación de router
import 'bootstrap/dist/css/bootstrap.min.css'; // Importación de Bootstrap solo una vez
import './assets/styles.css';
import axiosPlugin from './plugins/axios';

const app = createApp(App);
app.use(axiosPlugin); // Registra el plugin global de Axios
app.mount('#app');


createApp(App).use(router).mount('#app');
