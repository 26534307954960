<template>
  <div>
    <h1>Lista de Juegos</h1>
    <GameList />
  </div>
</template>

<script>
import GameList from './GameList.vue';

export default {
  components: {
    GameList
  }
}
</script>

<style scoped>
h1 {
  text-align: center;
  margin-bottom: 20px;
}
</style>
