<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark"> 
    <div class="container">
      <router-link to="/home" class="navbar-brand d-flex align-items-center">
        <img src="@/assets/logo.png" alt="ReviewPlay Logo" class="navbar-logo me-2" />
        <span class="navbar-text">ReviewPlay</span>
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <router-link to="/home" class="nav-link">Inicio</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/games" class="nav-link">Videojuegos</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about" class="nav-link">Sobre nosotros</router-link>
          </li>
        </ul>
        <div class="d-flex align-items-center">
          <span v-if="isAuthenticated" class="user-welcome me-3">Bienvenido, {{ username }}</span>
          <router-link v-if="!isAuthenticated" to="/register" class="btn btn-outline-light me-2">Registrarse</router-link>
          <router-link v-if="!isAuthenticated" to="/login" class="btn btn-primary">Iniciar Sesión</router-link>
          <button v-if="isAuthenticated" @click="logout" class="btn btn-outline-danger">Cerrar sesión</button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isAuthenticated: false,
      username: '',
    };
  },
  created() {
    this.checkAuthStatus();
  },
  methods: {
    checkAuthStatus() {
      this.isAuthenticated = !!localStorage.getItem('authToken');
      this.username = localStorage.getItem('username');
    },
    logout() {
      localStorage.removeItem('authToken');
      localStorage.removeItem('username');
      this.isAuthenticated = false;
      this.username = '';
      this.$router.push('/');
    },
  },
  watch: {
    $route() {
      this.checkAuthStatus();
    },
  },
};
</script>