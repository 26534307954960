<template>
  <div>
    <RatingFilter @filter-rating="updateRatingFilter" />
    <GameList :minRating="filteredRating" v-if="isAuthenticated" />
    <PlaceholderList v-else />
  </div>
</template>

<script>
import PlaceholderList from '@/components/PlaceholderList.vue';
import GameList from '@/components/GameList.vue';
import RatingFilter from '@/components/RatingFilter.vue';
import axiosInstance from '@/utils/axiosInstance';

export default {
  data() {
    return {
      isAuthenticated: false,
      filteredRating: 50 // Valor inicial para el filtro de rating
    };
  },
  methods: {
    async authenticate() {
      try {
        const response = await axiosInstance.get('http://217.160.4.144:8080/twitch/authenticate');
        console.log(response.data);
        this.isAuthenticated = true;
      } catch (error) {
        console.error('Error en la autenticación:', error);
      }
    },
    updateRatingFilter(newRating) {
      this.filteredRating = newRating;
    }
  },
  async created() {
    await this.authenticate();
  },
  components: {
    PlaceholderList,
    GameList,
    RatingFilter
  }
};
</script>
