<template>
  <div class="home-container">
    <div class="overlay">
      <h1>¡Bienvenido a ReviewPlay!</h1>
      <p>Explora, consulta y revisa tus videojuegos favoritos.</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isAuthenticated: false,
    };
  },
  methods: {
    async authenticate() {
      try {
        this.isAuthenticated = true;
      } catch (error) {
        console.error('Error al autenticar:', error);
      }
    },
  },
  async created() {
    await this.authenticate();
  },
};
</script>
