<template>
  <div class="about-container">
    <h1 class="about-title">Sobre Nosotros</h1>
    <p class="about-text">
      Bienvenido a ReviewPlay, una comunidad creada por apasionados de los videojuegos para apasionados de los videojuegos. Nuestro equipo está comprometido en ofrecerte reseñas detalladas, análisis objetivos y noticias actualizadas sobre los juegos más recientes y los clásicos que definieron una generación.
    </p>
    <p class="about-text">
      Nuestra misión es ayudarte a descubrir nuevos juegos, explorar géneros únicos y mantenerte al tanto de todas las novedades en el mundo del gaming. En ReviewPlay, valoramos tanto las opiniones de críticos como de jugadores como tú, quienes hacen de esta comunidad un espacio vibrante y lleno de pasión.
    </p>
    <p class="about-text">
      Nos enorgullece formar parte de una industria que une a personas de todo el mundo. ¡Gracias por ser parte de nuestra comunidad!
    </p>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
};
</script>
