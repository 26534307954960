export function generateImageUrl(url, size = 'screenshot_big') {
    const imageIdMatch = url.match(/\/([^/]+)\.jpg$/);
    if (!imageIdMatch) return null; // Manejo de errores si el formato no coincide
    const imageId = imageIdMatch[1];
    return `https://images.igdb.com/igdb/image/upload/t_${size}/${imageId}.jpg`;
  }

  

// Nombre	Dimensiones (px)	Descripción
// cover_small	90 x 128	Imagen pequeña de portada.
// screenshot_med	569 x 320	Captura media.
// cover_big	264 x 374	Imagen grande de portada.
// screenshot_big	889 x 500	Captura grande.
// screenshot_huge	1280 x 720	Captura en alta definición.
// 720p	1280 x 720	Imagen de 720p.
// 1080p	1920 x 1080	Imagen de 1080p.