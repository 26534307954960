<template>
  <div class="game-card">
    <img :src="coverUrl" alt="Portada del juego" class="game-cover" />
    <div class="game-info">
      <h2 class="game-title">{{ game.name }}</h2>
      <span class="game-genre">{{ genres && genres.length ? genres.join(', ') : 'Género desconocido' }}</span>
      <div class="metascore">
        <p class="score-label">CALIFICACIÓN</p>
        <p class="score-text">{{ game.total_rating ? getRatingText(game.total_rating) : 'Sin calificación' }}</p>
        <div class="score-value">{{ game.total_rating ? game.total_rating.toFixed(0) : 'N/A' }}</div>
        <p class="review-count">Basado en {{ game.total_rating_count || 0 }} reseñas</p>
        <div class="score-bar">
          <div :style="{ width: `${game.total_rating}%` }" :class="scoreColor(game.total_rating)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    game: Object,
    coverUrl: String,
    genres: Array
  },
  methods: {
    getRatingText(rating) {
      if (rating >= 75) return 'Muy Favorable';
      if (rating >= 50) return 'Aceptable';
      return 'Mediocre';
    },
    scoreColor(rating) {
      if (rating >= 75) return 'score-bar-green';
      if (rating >= 50) return 'score-bar-yellow';
      return 'score-bar-red';
    },
  },
};
</script>
